<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header ml-4">
            <signer-info :signer="signer"/>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="section-header">
                  <button class="btn btn-primary" type="button" @click="signReport" v-if="isSign">
                    Shartnomani imzolash
                  </button>
                  <loading v-else-if="loadingSign"/>
                  <b class="text-danger" v-if="signDataForUser.signedDocument.espStatus === 'DONE'">Shartnoma allaqachon
                    tasdiqlangan</b>
                </div>
                <div class="row mt-5" v-if="signDataForUser.signedDocument.espStatus !== 'DONE'">
                  <div class="col-12" :class="!loading ? 'frameHeight' : ''">
                    <loading v-if="loading"></loading>
                    <iframe v-show="!loading" id="reportFrame"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/common/Loading"
import SignerInfo from "./signer-info"
import EIMZOClient from '@/shared/sign/vue-e-imzo-client'
import SIGN from "../../shared/sign/sign-document"

export default {
  name: "registration",
  props: {
    userData: Object
  },
  components: {
    SignerInfo,
    Loading
  },
  data() {
    return {
      loading: false,
      loadingSign: false,
      signer: null,
      text: '',
      signDataForUser: {
        uuid: null,
        pkcs7: null,
        keyId: null,
        signedDocument: {
          signText: '',
          userTransferUuid: null,
          espStatus: 'PENDING',
          espType: ''
        },
        signRequest: {
          data: {
            TIN: null,
            PINFL: null
          }
        },
        isSigned: false
      }
    }
  },
  computed: {
    isKeyIdRequired() {
      return this.signDataForUser.keyId
    },
    isPkcs7Required() {
      return this.signDataForUser.pkcs7
    },
    isSign() {
      return !this.loadingSign && this.signer && !this.signer.vo.expired && !this.signDataForUser.isSigned &&
          this.signDataForUser.signedDocument.espStatus === 'PENDING' && this.text && this.text !== ''
    }
  },
  methods: {
    initUser() {
      if (this.signDataForUser.signedDocument.espStatus !== 'DONE') {
        this.loading = true
        this.$http.post(this.$props.userData.signedDocument.fileUrl, JSON.parse(this.$props.userData.signRequest.data.USER_DATA))
            .then(response => {
              this.setBlob(new Blob([response.data], {type: 'application/pdf'}))
              this.loading = false
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
      }
    },
    initDirector() {
      this.loading = true
      this.$http.get(this.$props.userData.signedDocument.fileUrl)
          .then(response => {
            const parts = response.data.split(';base64,');
            const contentType = parts[0].split(':')[1];
            const raw = window.atob(parts[1]);
            const uInt8Array = new Uint8Array(raw.length);

            for (let i = 0; i < raw.length; ++i) {
              uInt8Array[i] = raw.charCodeAt(i);
            }

            this.setBlob(new Blob([new Blob([uInt8Array], {type: contentType})], {type: 'application/pdf'}))
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
    },
    setBlob(blob) {
      let encodedString = URL.createObjectURL(blob)
      let iFrame = document.getElementById("reportFrame")
      iFrame.src = encodedString

      let reader = new FileReader()
      reader.readAsDataURL(blob)
      let vm = this
      reader.onloadend = function () {
        vm.text = reader.result
        vm.signDataForUser.signedDocument.signText = reader.result
      }
    },
    sign() {
      if (!this.isPkcs7Required) {
        this.$swal({title: 'Diqqat!', text: 'Imzolashda xatolik. pkcs7 topilmadi!', type: 'warning'})
        return
      }

      this.signDataForUser.signedDocument.signText = this.text

      let url = '/sign/user-registration'
      if (this.signDataForUser.signedDocument.espType === 'DIRECTOR_SIGN_REGISTERED_USER'){
        url = '/sign'
      }

      this.$http.post(url, this.signDataForUser)
          .then(res => {
            if (res.data.status === 'BAD_REQUEST') {
              this.$swal({title: 'fail', text: res.data.message, type: 'warning'})
            } else {
              this.$swal({title: 'success', text: res.data.message, type: 'success'})
            }
            this.loadingSign = false
            this.signDataForUser.isSigned = true
          }, err => {
            this.loadingSign = false
            console.log(err)
          })
    },
    signReport() {
      const vm = this;
      this.loadingSign = true
      if (!vm.isKeyIdRequired) {
        EIMZOClient.loadKey(vm.signer.vo, function (id) {
          vm.signDataForUser.keyId = id
          EIMZOClient.createPkcs7(id, vm.signDataForUser.signedDocument.signText, null, function (pkcs7) {
            vm.signDataForUser.pkcs7 = pkcs7
            vm.sign()
          }, function (e, r) {
            SIGN.errorMessage(r, e)
            vm.loadingSign = false
          })
        }, function (e, r) {
          SIGN.errorMessage(r, e)
          vm.loadingSign = false
        })
      } else {
        EIMZOClient.createPkcs7(vm.signDataForUser.keyId, vm.signDataForUser.signedDocument.signText, null, function (pkcs7) {
          vm.signDataForUser.pkcs7 = pkcs7
          vm.sign()
        }, function (e, r) {
          SIGN.errorMessage(r, e)
          vm.loadingSign = false
        })
      }
    }
  },
  created() {
    this.signDataForUser.signRequest = this.$props.userData.signRequest
    this.signDataForUser.signedDocument = this.$props.userData.signedDocument
    if (!this.signDataForUser.signRequest.data.PINFL) {
      this.$swal({title: 'Diqqat!', text: 'PINFL topilmadi!', type: 'warning'})
      return
    }

    SIGN.signInit()

    if (this.signDataForUser.signedDocument.espType === 'DIRECTOR_SIGN_REGISTERED_USER') {
      setTimeout(() => {
        this.signer = SIGN.singer('ENTITY', this.signDataForUser.signRequest.data.TIN, this.signDataForUser.signRequest.data.PINFL)
      }, 1000)

      this.initDirector()
    } else {
      setTimeout(() => {
        this.signer = SIGN.singer('INDIVIDUAL', null, this.signDataForUser.signRequest.data.PINFL)
      }, 1000)

      this.initUser()
    }
  },
  watch: {
    text: function () {
      this.signDataForUser.signedDocument.signText = this.text
    }
  }
}
</script>

<style scoped>
#reportFrame, #reportFrameContract {
  width: 97%;
  height: 100%;
}

.frameHeight {
  height: 700px;
}

.t-h100 {
  height: 150px;
  overflow-y: scroll;
}

textarea {
  border: 1px solid #2b8dc6 !important;
  border-radius: 10px;
}
</style>